import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`1-1 and group chat
Build trust and engagement with your users by connecting individuals or groups through their favorite mode of communication.`}</p>
    <p>{`Rich media messaging
Send multiple messages, pictures, and files with just a few clicks. React with comments or emojis to provide deeper context to conversations.`}</p>
    <p>{`Webhooks
Extend in-app chat functionality, integrate existing workflows, monitor user actions and behaviors, or gather analytics—the choice is yours.`}</p>
    <p>{`Message storage
Access the histories of all of your chat rooms. We store messages so you don’t have to.`}</p>
    <p>{`Roles & permissions`}</p>
    <p>{`Set and update permissions programmatically to control access and create any type of role, from admin to read-only.`}</p>
    <p>{`Typing indicators`}</p>
    <p>{`Let your users see when someone else is typing in a room or group or private message.`}</p>
    <p>{`Read cursors`}</p>
    <p>{`Show your users how far they and their conversation partners have read each chat.`}</p>
    <p>{`Online presence`}</p>
    <p>{`Prominently display which users are online or offline in rooms and across the whole chat app.`}</p>
    <p>{`Push notifications`}</p>
    <p>{`Keep your users engaged. Bring them back into the conversation with both mobile and web notifications.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      